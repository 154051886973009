import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <footer className="footer-area-two">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info">
                                <h4>Email</h4>
                                <span>
                                    <a href="mailto:contacto@valsol.cl">contacto@valsol.cl</a>
                                </span>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info">
                                <h4>Casa matriz</h4>
                                <span>San Carlos, Región de Ñuble</span>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info">
                                <h4>Sucursal</h4>
                                <span>Santiago, Region Metropolitana</span>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="bar"></div>
                        </div>

                        <div className="col-lg-7 col-md-7">
                            <p><i className="far fa-copyright"></i> 2019 <Link to="/">Muebles ValSol</Link>. Todos los derechos reservados</p>
                        </div>

                        <div className="col-lg-5 col-md-5">
                            <p style={{textAlign: 'right'}}>Sitio desarrollado por <a href="https://www.neokode.cl" target="_blank" rel="noopener noreferrer">Neokode</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
