import React, { Component } from 'react';
import { Link } from "react-router-dom";

class NavbarSix extends Component {
    render() {
        return (
            <React.Fragment>
            <nav id="navbar" className="navbar navbar-expand-lg navbar-style-two navbar-light bg-light">
                <div className="container">
                    <Link to="/" className="navbar-brand">
                        <img src={require('../../images/white-logo.png')} alt="logo" />
                    </Link>
                </div>
            </nav>
            </React.Fragment>
        );
    }
}

export default NavbarSix;
