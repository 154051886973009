import React, { Component } from 'react';

class ClientType extends Component {
    render() {
        return (
            <section id="client-type" className="boxes-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-box">
                                <i className="fas fa-building"></i>
                                <h3>Empresas</h3>
                                <p>Asesoría personalizadas a empresas que quieran un espacio de trabajo confortable y armónico.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-box">
                                <i className="fas fa-user"></i>
                                <h3>Particulares</h3>
                                <p>Te apoyamos en tus proyectos de hogar con nuestros productos y servicios.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div className="single-box">
                                <i className="fas fa-check"></i>
                                <h3>Otros</h3>
                                <p>Realizamos proyectos a medida de tus necesidades, si tienes algún requerimiento especial, contáctanos.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ClientType;
