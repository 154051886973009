import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel3';

const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='fas fa-angle-left'></i>",
        "<i class='fas fa-angle-right'></i>"
    ],
    responsive: {
        0: {
            items:2,
        },
        768: {
            items:3,
        },
        1024: {
            items: 4,
        },
        1200: {
            items:6,
        }
    }
}

class Partner extends Component {

    state = { display:false};

    componentDidMount(){
        this.setState({ display: true })
    }

    render() {
        const customers = [
          require('../../images/cus-wallmart.png'),
          require('../../images/cus-cencosud.png'),
          require('../../images/cus-cruzverde.png'),
          require('../../images/cus-nuevamasvida.png'),
          require('../../images/cus-conardi.png'),
          require('../../images/cus-asfal.png'),
          require('../../images/cus-santarita.png')
        ];
        return (
            <section className="partner-area">
                <div className="container">
                    <h3>Nuestros clientes nos respaldan</h3>

                    <div className="row">
                        {this.state.display ? <OwlCarousel
                            className="partner-slides owl-carousel owl-theme"
                            {...options}
                        >
                            {
                              customers.map((customer, index) => {
                                return (
                                  <div key={index} className="col-lg-12 col-md-12">
                                      <div className="partner-item">
                                          <a href="/" onClick={(evt) => {evt.preventDefault()}}>
                                              <img src={customer} alt="logo" />
                                          </a>
                                      </div>
                                  </div>
                                )
                              })
                            }
                        </OwlCarousel> : ''}
                    </div>
                </div>
            </section>
        );
    }
}

export default Partner;
