import React, { Component } from 'react';
import Navbar from '../components/Home/Navbar';
import Banner from '../components/Home/Banner';
import ClientType from '../components/Home/ClientType';
import About from '../components/Home/About';
import Services from '../components/Home/Services';
import Works from '../components/Home/Works';
import Funfact from '../components/Home/Funfact';
import Products from '../components/Home/Products';
import Partner from '../components/Home/Partner';
import Contact from '../components/Home/Contact';
import Footer from '../components/Home/Footer';

class Index extends Component {
    render() {
        return (
            <React.Fragment>
                <Navbar />
                <Banner />
                <ClientType />
                <About />
                <Services />
                <Works />
                <Funfact />
                <Products />
                <Partner />
                <Contact />
                <Footer />
            </React.Fragment>
        );
    }
}

export default Index;
