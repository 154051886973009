import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Banner extends Component {

    render() {
        return (
            <section className="page-title-area jarallax" data-jarallax='{"speed": 0.2}'>
                <div className="container">
                    <h2>Algo sali&oacute; mal... la p&aacute;gina que buscas no existe</h2>
                </div>
                <div className="main-banner-content">
                  <Link to="/" className="btn btn-primary">Ir al inicio</Link>
                </div>
                <div className="container error-area ptb-100">
                    <div className="error-content">
                        <img src={require("../../images/404.png")} alt="error" />
                    </div>
                </div>
            </section>
        );
    }
}

export default Banner;
