import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel3';
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='fas fa-chevron-left'></i>",
        "<i class='fas fa-chevron-right'></i>"
    ],
    responsive: {
        0: {
            items:1,
        },
        768: {
            items:2,
        },
        1200: {
            items:3,
        }
    }
}
const products = [
  {url: '/images/products/product-1.jpg', name: 'Letrero de acrílico', desc: 'Luminosos o sólidos'},
  {url: '/images/products/product-2.jpg', name: 'Rack de madera', desc: 'Diferentes diseños'},
  {url: '/images/products/product-3.jpg', name: 'Mesa de madera', desc: 'Estilo rústica'},
  {url: '/images/products/product-4.jpg', name: 'Puerta de madera', desc: 'Con vidrio'},
  {url: '/images/products/product-5.jpg', name: 'Estantes', desc: 'Personalizados'},
  {url: '/images/products/product-6.jpg', name: 'Recepción', desc: 'Diseño según espacio'},
  {url: '/images/products/product-7.jpg', name: 'Mueble de cocina', desc: 'Diferentes tipos'},
  {url: '/images/products/product-8.jpg', name: 'Vitrina de vidrio', desc: 'Iluminada'},
  {url: '/images/products/product-9.jpg', name: 'Letrero de madera', desc: 'A medida'},
  {url: '/images/products/product-10.jpg', name: 'Mueble de baño', desc: 'Diferentes modelos'},
  {url: '/images/products/product-11.jpg', name: 'Escritorio', desc: 'Hogar y oficina'}
]

class Products extends Component {
    state = {
      display:false,
      photoIndex: 0,
      isOpen: false
    };

    componentDidMount(){
        this.setState({ display: true })
    }
    render() {
        const { photoIndex, isOpen } = this.state;
        return (
            <section id="products" className="products ptb-100 bg-f5fbff">
                <div className="container">
                    <div className="default-section-title">
                        <span>Muebles</span>
                        <h2>Productos</h2>
                    </div>

                    <div className="row">
                        {this.state.display ? <OwlCarousel
                            className="products-slides owl-carousel owl-theme"
                            {...options}
                        >
                            {
                              products.map((product, index) => {
                                return (
                                  <div key={index} className="col-lg-12 col-md-12">
                                      <a href="#" className="popup-btn" onClick={e => {e.preventDefault(); this.setState({ photoIndex: 0, isOpen: true })}}>
                                      <div className="single-product">
                                          <img src={product.url} alt="product" />
                                          <div className="product-content">
                                              <h3><a href="/" onClick={(evt) => {evt.preventDefault()}}>{product.name}</a></h3>
                                              <span>{product.desc}</span>
                                          </div>
                                      </div>
                                      </a>
                                  </div>
                                )
                              })
                            }
                        </OwlCarousel> : ''}
                    </div>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={products[photoIndex].url}
                        nextSrc={products[(photoIndex + 1) % products.length].url}
                        prevSrc={products[(photoIndex + products.length - 1) % products.length].url}
                        imageTitle={photoIndex + 1 + "/" + products.length}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + products.length - 1) % products.length
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % products.length
                        })
                        }
                    />
                )}
            </section>
        );
    }
}

export default Products;
