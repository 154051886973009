import React from 'react'
import Navbar from '../components/NotFound/Navbar';
import Banner from '../components/NotFound/Banner';

class NotFound extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Navbar />
                <Banner />
            </React.Fragment>
        );
    }
}

export default NotFound;
