import React, { Component } from 'react';
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import { Link } from "react-router-dom";
import mixitup from 'mixitup';

const images = [
    {url: "/images/works/work-2.jpg", name: "Diseño", desc: "Muebles y espacios", tags: "dis mue are"},
    {url: "/images/works/work-1.jpg", name: "Locales comerciales", desc: "Muebles y letreros", tags: "let are mue"},
    {url: "/images/works/work-3.jpg", name: "Vitrinas", desc: "Vidrio y acrílico", tags: "mue"},
    {url: "/images/works/work-4.jpg", name: "Decoración", desc: "Modernización de espacios", tags: "dis mue are"},
    {url: "/images/works/work-5.jpg", name: "Recepción", desc: "Muebles", tags: "mue are"},
    {url: "/images/works/work-6.jpg", name: "Hogar", desc: "Muebles personalizados", tags: "mue ins"},
    {url: "/images/works/work-7.jpg", name: "Letreros", desc: "Luminosos", tags: "let ins"},
    {url: "/images/works/work-9.jpg", name: "Oficinas", desc: "Muebles y uso de espacios", tags: "mue ins"},
    {url: "/images/works/work-8.jpg", name: "Atención a público", desc: "Muebles personalizados", tags: "mue ins dis are"}
];

class Works extends Component {

    state = {
        photoIndex: 0,
        isOpen: false
    };

    componentDidMount() {
      mixitup("#mix-wrapper", {
          animation: {
              effects: "fade rotateZ(0deg)",
              duration: 700
          },
          classNames: {
              block: "programs",
              elementFilter: "filter-btn",
          },
          selectors: {
              target: ".mix-target"
          }
      });
    }

    render() {
        const { photoIndex, isOpen } = this.state;
        return (
            <section id="portfolio" className="portfolio-area ptb-100 bg-f9f9f9">
                <div className="container">
                    <div className="default-section-title">
                        <h2>Proyectos</h2>
                        <p>Tenemos un amplio conocimiento de los procesos productivos además de una gran experiencia en el manejo de materiales. Disponemos de maquinaria renovada y un equipo de montadores profesionales que nos permiten realizar proyectos “llave en mano” si el cliente así lo necesita.</p>
                        <p>Contamos con un excelente departamento técnico y comercial listos para abordar cualquier tipo de proyecto ofreciendo siempre a nuestros clientes la asesoría especializada para que el resultado del proyecto sea un producto final impecable y de alto valor competitivo.</p>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="shorting-menu">
                                <button className="filter" data-filter="all">Todos</button>
                                <button className="filter" data-filter=".mue">Muebles</button>
                                <button className="filter" data-filter=".dis">Diseño</button>
                                <button className="filter" data-filter=".int">Instalación</button>
                                <button className="filter" data-filter=".let">Letrero</button>
                                <button className="filter" data-filter=".are">Áreas</button>
                            </div>
                        </div>
                    </div>

                    <div className="shorting">
                        <div className="row" id="mix-wrapper">
                            {
                              images.map((image, index) => {
                                return (
                                  <div key={index} className={"col-lg-4 col-md-6 mix mix-target " + image.tags}>
                                      <div className="single-work">
                                          <Link to="#" className="popup-btn" onClick={e => {e.preventDefault(); this.setState({ photoIndex: 0, isOpen: true })}}></Link>
                                          <div className="work-image">
                                              <img src={image.url} alt="work" />
                                              <div className="work-overlay">
                                                  <h3>{image.name}</h3>
                                                  <span>{image.desc}</span>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                )
                              })
                            }
                        </div>
                    </div>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex].url}
                        nextSrc={images[(photoIndex + 1) % images.length].url}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length].url}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % images.length
                        })
                        }
                    />
                )}
            </section>
        );
    }
}

export default Works;
