import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

class Banner extends Component {

    render() {
        return (
            <React.Fragment>
                <div id="home" className="main-banner jarallax" data-jarallax='{"speed": 0.2}'>
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="main-banner-content">
                                    <h1>Te asesoramos en tus proyectos con la mejor experiencia</h1>
                                    <p>&nbsp;</p>
                                    <div className="btn-box">
                                        <AnchorLink
                                            onClick={this.toggleNavbar}
                                            offset={() => 0}
                                            className="btn btn-primary"
                                            href="#contact"
                                        >
                                            Contáctanos
                                        </AnchorLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Banner;
