import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel3';

const options = {
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='fas fa-angle-left'></i>",
        "<i class='fas fa-angle-right'></i>"
    ],
    responsive: {
        0: {
            items:1,
        },
        768: {
            items:2,
        },
        1200: {
            items:3,
        }
    }
}

class Services extends Component {

    state = { display:false};

    componentDidMount(){
        this.setState({ display: true })
    }

    render() {
        return (
            <section className="services-area ptb-100 pt-0">
                <div className="container">
                    <div className="row">
                        {this.state.display ? <OwlCarousel
                            className="services-slides owl-carousel owl-theme"
                            {...options}
                        >
                            <div className="col-lg-12 col-md-12">
                                <div className="single-services">
                                    <i className="fas fa-check-double"></i>
                                    <h3>Experiencia</h3>
                                    <p>Con más de 15 años en el mercado nos hemos especializado en asesorar a nuestros clientes. No es sólo fabricar un mueble, es saber que cada pieza tiene su lugar.</p>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="single-services">
                                    <i className="fas fa-award"></i>
                                    <h3>Calidad</h3>
                                    <p>Nos esforzamos día a día en entregar productos y servicios de calidad. Estamos en constante búsqueda de mejoras que se traduzcan en un servicio integral.</p>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="single-services">
                                    <i className="fas fa-chair"></i>
                                    <h3>Diseño</h3>
                                    <p>Nuestra basta experiencia nos permite entender los sueños de nuestros clientes y entregar lo que se espera. Nuestro trabajo en terreno marca la diferencia.</p>
                                </div>
                            </div>

                        </OwlCarousel> : ''}
                    </div>
                </div>
            </section>
        );
    }
}

export default Services;
