import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
            <section id="about" className="about-area-two ptb-100">
                <div className="container">
                    <div className="about-section-title">
                        <h2>Somos</h2>
                        <p>Un equipo de profesionales con 16 años de experiencia en el diseño, fabricación y comercialización de mobiliario a medida y de alto confort para nuestros clientes.
                        Nos aseguramos que nuestros modelos sean totalmente funcionales, cómodos y de buen diseño para que tu espacio luzca simplemente genial!</p>
                        <p>Con cobertura en todo Chile nos preocupamos por hacer un buen trabajo.</p>
                    </div>
                </div>
            </section>
        );
    }
}

export default About;
