import React, { Component } from 'react';
import config from '../../config';

class Contact extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        isError: false,
        isSuccess: false,
        name: '',
        email: '',
        phone: '',
        message: '',
        isErrorName: false,
        isErrorEmail: false,
        isErrorPhone: false,
        isErrorMessage: false
      };
    }
    handleChange = event => {
      this.setState({
        [event.target.id]: event.target.value
      });
    }
    validate() {
      var errors = {};
      errors.isErrorName = this.state.name.length < 3;
      errors.isErrorEmail = this.state.email.length < 8;
      errors.isErrorPhone = this.state.phone.length < 8;
      errors.isErrorMessage = this.state.message.length < 3;
      errors.hasError = errors.isErrorName || errors.isErrorEmail || errors.isErrorPhone || errors.isErrorMessage;
      return errors;
    }
    handleSubmit = async event => {
      event.preventDefault();
      const errors = this.validate();
      if (errors.hasError) {
        errors.hasError = undefined;
        this.setState({...errors});
        return;
      }
      this.setState({ ...errors, isLoading: true });
      try {
        const response = await this.contact({
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          message: this.state.message
        });
        console.log(response);
        this.setState({ isLoading: false, isError: false, isSuccess: true, name: '', email: '', phone: '', message: '' });
      } catch (e) {
        console.error(e);
        this.setState({ isLoading: false, isError: true, isSuccess: false });
      }
    }
    contact(data) {
      return fetch(config.url.api + '/contact', {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' }
        })
        .then(res => {
          //console.log(res.status);
        });
    }
    handleClose = (event) => {
      this.setState({isErrorMessage: false});
    }
    render() {
        return (
            <section id="contact" className="contact-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <div className="contact-content">
                                <h3>Déjanos un mensaje</h3>
                                <h6>Tu opinión nos ayuda a mejorar.</h6>
                                <p>Si quieres consultar por tu proyecto, necesitas asesoría u otro motivo completa el formulario y te contactamos a la brevedad.</p>
                                <div className="icon"><i className="fas fa-headset"></i></div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12">
                            <form id="contactForm" className="home-contact-form" onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input id="name" value={this.state.name} type="text" placeholder="Nombre" className={"form-control" + (this.state.isErrorName ? " is-invalid" : "")} required={true} data-error="Ingresa tu nombre" onChange={this.handleChange} />
                                            <div className="help-block with-errors">
                                            {
                                              this.state.isErrorName &&
                                              <div className="invalid-feedback">
                                                Debes ingresar tu nombre
                                              </div>
                                            }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input id="phone" value={this.state.phone} type="text" placeholder="Teléfono" className={"form-control" + (this.state.isErrorPhone ? " is-invalid" : "")} required={true} data-error="Ingresa tu teléfono" onChange={this.handleChange} />
                                            <div className="help-block with-errors">
                                            {
                                              this.state.isErrorPhone &&
                                              <div className="invalid-feedback">
                                                Debes ingresar tu tel&eacute;fono
                                              </div>
                                            }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <input id="email" value={this.state.email} type="email" placeholder="E-mail" className={"form-control" + (this.state.isErrorEmail ? " is-invalid" : "")} required={true} data-error="Ingresa tu email" onChange={this.handleChange} />
                                            <div className="help-block with-errors">
                                            {
                                              this.state.isErrorEmail &&
                                              <div className="invalid-feedback">
                                                Debes ingresar tu email
                                              </div>
                                            }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea id="message" value={this.state.message} placeholder="Mensaje" className={"form-control" + (this.state.isErrorMessage ? " is-invalid" : "")} cols="30" rows="5" required={true} data-error="Escribe el mensaje" onChange={this.handleChange} />
                                            <div className="help-block with-errors">
                                            {
                                              this.state.isErrorMessage &&
                                              <div className="invalid-feedback">
                                                Debes ingresar el mensaje
                                              </div>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                      (this.state.isError || this.state.isSuccess) &&
                                      <div className={"alert alert-" + (this.state.isError ? "danger" : "success") + " col-lg-12 col-md-12"} role="alert">
                                        {this.state.isError ? "Error al enviar el mensaje, por favor intenta nuevamente." : "Mensaje enviado exitosamente. Nos comunicaremos contigo prontamente."}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.handleClose}>
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                    }
                                    {
                                      <div className="col-lg-12 col-md-12">
                                          <button type="submit" className="btn btn-primary" disabled={this.state.isLoading}>
                                            {
                                              this.state.isLoading ?
                                              "Enviando..."
                                              :
                                              "Enviar Mensaje"
                                            }
                                          </button>
                                          <div id="msgSubmit" className="h3 text-center hidden"></div>
                                          <div className="clearfix"></div>
                                      </div>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Contact;
